import { template as template_5adb5b8a3a2e4e0483c5b5d96ec7e922 } from "@ember/template-compiler";
const FKText = template_5adb5b8a3a2e4e0483c5b5d96ec7e922(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
