import { template as template_4b3717c6ca3c48069da7b3e41111b62f } from "@ember/template-compiler";
const WelcomeHeader = template_4b3717c6ca3c48069da7b3e41111b62f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
