import { template as template_003d165a0555466498a128024193d7c6 } from "@ember/template-compiler";
const FKLabel = template_003d165a0555466498a128024193d7c6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
